import React from "react";
import PlaceHolderSite from "../../PlaceHolderSite/PlaceHolderSite";

export default function MiHome() {
    return (
        <div id="MiTeqers">
            <PlaceHolderSite city={'mi'}/>
        </div>
    );
}
