import React from "react";
import "../../App.scss";

export default function CurvedDividerTop() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="10 0 600.31 29.17"
    >
      <path
        d="M611,25.71S488,6.44,305,6.44-1,25.71-1,25.71V-2.47H611Z"
        transform="translate(1.15 2.47)"
        className="rectangleStyle"
      />
      <path
        d="M611,25.71S488,6.44,305,6.44-1,25.71-1,25.71"
        transform="translate(1.15 2.47)"
        className="lineStyle"
      />
    </svg>
  );
}
