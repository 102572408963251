import React, { useState, useEffect } from "react";
import "./MenuContent.scss";
import { Link } from "react-scroll";
import DropDown from "../DropDown/DropDown";

const locations = [
  {
    id: 0,
    title: "#USTeqers",
    link: "/lateqers"
  },
  {
    id: 1,
    title: "#SDTeqers",
    link: "/sdteqers"
  },
  {
    id: 2,
    title: "#MITeqers",
    link: "/miteqers"
  },
  {
    id: 3,
    title: "#LATeqers",
    link: "/lateqers"
  },
  {
    id: 4,
    title: "#NYTeqers",
    link: "/nyteqers"
  },
  {
    id: 5,
    title: "#DCTeqers",
    link: "/dcteqers"
  }
];

export default function MenuContent() {
  return (
    <ul className="menu">
      <li>
        <Link
          activeClass="active"
          to="introduction"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Introduction
        </Link>
        <span className="menuBottomLine"></span>
      </li>
      <li>
        <Link
          activeClass="active"
          to="aboutus"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          About Us
        </Link>
        <span className="menuBottomLine"></span>
      </li>
      <li>
        <Link
          activeClass="active"
          to="trainings"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Trainings
        </Link>
        <span className="menuBottomLine"></span>
      </li>
      <li>
        <a
          href="https://www.fiteq.org/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register / Login
        </a>
        <span className="menuBottomLine"></span>
      </li>
      <li>
        <Link
          activeClass="active"
          to="contactus"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Contact Us
        </Link>
        <span className="menuBottomLine"></span>
      </li>
      <li>
        <DropDown locations={locations} selectedId={3} />
      </li>
    </ul>
  );
}
