import React from "react";
import PlaceHolderSite from "../../PlaceHolderSite/PlaceHolderSite";

export default function UsHome() {
  return (
      <div id="UsTeqers">
         <PlaceHolderSite city={'us'}/>
      </div>
  );
}
