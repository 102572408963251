import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./Carousel.scss";
import CarouselPhoto1 from "../../images/carousel/carousel1.jpg";
import CarouselPhoto2 from "../../images/carousel/carousel2.jpg";
import CarouselPhoto3 from "../../images/carousel/carousel3.jpg";
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.css";
import CurvedDividerTop from "../CurvedDividerTop/CurvedDividerTop";

const CarouselTraining = () => {
  return (
    <div className="CarouselContainer">
        <h1 className="sectionTitle">Trainings</h1>
        <div className="dividerContainerTop">
            <CurvedDividerTop/>
        </div>
      <Carousel
        autoPlay
        showArrows={true}
        className="trainingCarousel"
        showThumbs={false}
        stopOnHover={true}
        showStatus={false}
        swipeable={true}
        showIndicators={true}
        infiniteLoop={true}
        interval={5000}
      >
        <div>
          <img src={CarouselPhoto1} />
          <div className="slideTextContent firstSlideContainer">
            <h2>
              We host several training sessions to develop and train all skill
              levels
            </h2>
            <p className="carouselSmallText">
              We teach the fundamentals of Teqball to progress in advanced
              techniques with each session. The purpose is to create familiarity
              and confidence with the sport to eventually compete at the
              tournament level.
            </p>
          </div>
        </div>
        <div>
          <img src={CarouselPhoto3} />
          <div className="slideTextContent secondSlideContainer">
            <h2>All Female Clinics</h2>
            <p className="carouselSmallText">
              This program teaches the fundamentals of Teqball whilst using
              creativity and confidence with the integration of common soccer
              techniques mixed with Teqball. It will provide the opportunity to
              develop teamwork and meet a community of different female Teqers
              who share a joint love for Teqball and sports.
            </p>
          </div>
        </div>
        <div>
          <img src={CarouselPhoto2} />
          <div className="slideTextContent thirdSlideContainer">
            <h2 id="virtualTraining">Virtual Training</h2>
            <p className="carouselSmallText">
              This program is ideal for those who are always on the move but
              still want to stay connected and learn. These training are
              available online and range in various levels to ensure all
              techniques are covered. These 15 minute sessions of fun are
              available on instagram live and available the following evening on
              youtube.
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselTraining;
