import React from "react";
import "./AboutUs.scss";
import { Element } from "react-scroll";

export default function AboutUs(city) {
  return (
    <section className="aboutUs">
      <Element name="aboutus"></Element>
      <h1 className="sectionTitle">About Us</h1>
      <div className="avatarContainer">
        <div className="avatarContent">
          <div className="avatarItem aboutUs1"></div>
        </div>
        <div className="avatarContent">
          <div className="avatarItem aboutUs2"></div>
        </div>
        <div className="avatarContent">
          <div className="avatarItem aboutUs3"></div>
        </div>
        <div className="avatarContent">
          <div className="avatarItem aboutUs4"></div>
        </div>
      </div>
      <div className="textContainer">
        <p className="strong">
          LA Teqers are a professional Teqball academy that produce Teqball
          athlete’s that compete at national tournaments and competitions whilst
          also empowering the everyday person to experience the sport of the
          future that is Teqball.
        </p>
        <p>
          Teqball is a gender neutral soccer-foundation based sport that is
          perfectly suitable for amateur enthusiasts and high level soccer
          players. The game consists of a table similar to a ping pong table,
          however, curved and players utilize different parts of the body and
          methods similar to soccer, to play. We offer many ways for
          individual’s to get involved through training, practices, leagues and
          tournaments.
        </p>
        <p>
          Our next step in the growth of Teqball, is driven by our long term
          vision to be an Olympic sport by 2028. As part of that growth we have
          introduced Teqball at the grassroots level in Southern California and
          throughout the United States via organic play experience’s and running
          pick-up games, clinic’s, practices and tournaments. Join us and
          experience the sport of the future!
        </p>
      </div>
    </section>
  );
}
