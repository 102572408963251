import React, { useState } from "react";
import "./DropDown.scss";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function DropDown({ locations, selectedId }) {
  const [listOpen, setListOpen] = useState(false);

  const toggleList = () => {
    setListOpen(!listOpen);
  };
  const closeList = () => {
    setListOpen(false);
  };

  return (
    <div className="dd-wrapper" onMouseLeave={() => closeList()}>
      <div className="dd-header" onClick={() => toggleList()}>
        <div className="dd-header-title">
          {locations[selectedId].title}
          {listOpen ? (
            <ArrowDropUpIcon fontSize="large"/>
          ) : (
            <ArrowDropDownIcon fontSize="large"/>
          )}
        </div>
      </div>
      {listOpen && (
        <ul className="dd-list">
          {locations.map(item => (
            <li className="dd-list-item" key={item.id}>
              <Link to={item.link}>{item.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
