import React from "react";
import PlaceHolderSite from "../../PlaceHolderSite/PlaceHolderSite";

export default function SdHome() {
  return (
      <div id="SdTeqers">
          <PlaceHolderSite city={'sd'}/>
      </div>
  );
}
