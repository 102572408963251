import React from "react";
import Typography from "@material-ui/core/Typography";
import "../../App.scss";
import "./Training.scss";
import CarouselTraining from "../Carousel/Carousel";
import {Element} from "react-scroll";
const Training = city => {
  return (
    <section className="training">
        <Element name="trainings"></Element>
      <div className="dividerContainerBottom">
        <div className="dividerShapeBottom orange"></div>
      </div>
      <CarouselTraining />
    </section>
  );
};

export default Training;
