import React from "react";
import "./ContactUs.scss";

export default function AthleteAvatar({ name, avatar }) {
  return (
    <div className="athletesAvatarContent">
      <div className={`athletesAvatar ${avatar}`}></div>
      <div className="athletesName">{name}</div>
    </div>
  );
}
