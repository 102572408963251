import React from "react";
import { Link } from "react-router-dom";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import "./CitySelector.scss";
import LogoUsTeqers from "../../images/logos/logo_usteqers.png";
import LogoSdTeqers from "../../images/logos/logo_sdteqers.png";
import LogoMiTeqers from "../../images/logos/logo_miteqers.png";
import LogoLaTeqers from "../../images/logos/logo_lateqers.png";
import LogoNyTeqers from "../../images/logos/logo_nyteqers.png";
import LogoDcTeqers from "../../images/logos/logo_dcteqers.png";

export default function CitySelector() {
  return (
    <div id="citySelector">
      <HeaderMenu />
      <div className="citySelectorContainer">
        <div className="citySelectorContent us">
          <Link to={"/lateqers"}>
            <div className="cityLogoContainer">
              <img src={LogoUsTeqers} className="cityLogo" alt="US Teqers" />
              <span className="cityLogoText">USA</span>
            </div>
          </Link>
        </div>
        <div className="citySelectorContent sd">
          <Link to={"/sdteqers"}>
            <div className="cityLogoContainer">
              <img
                src={LogoSdTeqers}
                className="cityLogo"
                alt="San Diego Teqers"
              />
              <span className="cityLogoText">San Diego</span>
            </div>
          </Link>
        </div>
        <div className="citySelectorContent mi">
          <Link to={"/miteqers"}>
            <div className="cityLogoContainer">
              <img src={LogoMiTeqers} className="cityLogo" alt="Miami Teqers" />
              <span className="cityLogoText">Miami</span>
            </div>
          </Link>
        </div>
        <div className="citySelectorContent la">
          <Link to={"/lateqers"}>
            <div className="cityLogoContainer">
              <img
                src={LogoLaTeqers}
                className="cityLogo"
                alt="Los Angeles Teqers"
              />
              <span className="cityLogoText">Los Angeles</span>
            </div>
          </Link>
        </div>
        <div className="citySelectorContent ny">
          <Link to={"/nyteqers"}>
            <div className="cityLogoContainer">
              <img
                src={LogoNyTeqers}
                className="cityLogo"
                alt="New York Teqers"
              />
              <span className="cityLogoText">New York</span>
            </div>
          </Link>
        </div>
        <div className="citySelectorContent dc">
          <Link to={"/dcteqers"}>
            <div className="cityLogoContainer">
              <img
                src={LogoDcTeqers}
                className="cityLogo"
                alt="Washington DC Teqers"
              />
              <span className="cityLogoText">Washington</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
