import React from "react";
import PlaceHolderSite from "../../PlaceHolderSite/PlaceHolderSite";

export default function NyHome() {
    return (
        <div id="NyTeqers">
            <PlaceHolderSite city={'ny'}/>
        </div>
    );
}
