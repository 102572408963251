import React, { useEffect, useRef, useState } from "react";
import HeaderContent from "../../HeaderContent/HeaderContent";
import Introduction from "../../Introduction/Introduction";
import LaAboutUs from "../../AboutUs/AboutUs";
import LaTraining from "../../Training/Training";
import ContactUs from "../../ContactUs/ContactUs";
import Footer from "../../Footer/Footer";
import StickyHeader from "../../StickyHeader/StickyHeader";

export default function LaHome() {
  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    document.documentElement.scrollTop > 150
      ? setSticky(true)
      : setSticky(false);
  };

  const debounce = (func, wait = 20, immediate = true) => {
    let timeOut;
    return () => {
      let context = this,
        args = arguments;
      const later = () => {
        timeOut = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  window.addEventListener("scroll", debounce(handleScroll));

  return (
    <div id="LaTeqers">
      <HeaderContent />
      <StickyHeader sticky={isSticky} />
      <Introduction />
      <LaAboutUs />
      <LaTraining />
      <ContactUs />
      <Footer selected={"lateqers"} />
    </div>
  );
}
