import React from "react";
import "./HeaderMenu.scss";
import TeqballLogo from "../../images/logos/logo_teqball.png";
import CurvedDividerTop from "../CurvedDividerTop/CurvedDividerTop";

export default function HeaderMenu() {
  return (
    <div id="headerMenu">
      <div className="dividerContainerTop orange">
        <CurvedDividerTop />
      </div>
      <div className="headerContent">
        <div className="headerLogoContainer">
          <img
            src={TeqballLogo}
            alt="Teqball, the world is curved!"
            className="headerLogo"
          />
        </div>
        <div className="titleText">
          <h1>PICK A CITY</h1>
        </div>
      </div>
    </div>
  );
}
