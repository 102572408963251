import React from "react";
import "./Introduction.scss";
import { Element } from "react-scroll";
import TeqballTable from "../../images/introduction/teqball_table.jpg";
import VideoEmbedContent from "../VideoEmbedContent/VideoEmbedContent";

export default function Introduction() {
  return (
    <section className="introduction">
      <Element name="introduction"></Element>
      <div className="dividerContainerBottom">
        <div className="dividerShapeBottom"></div>
      </div>
      <div className="textContainer">
        <p>
          Teqball is a football-based sport, played on a specially-curved table
          (the Teq table), which is attracting a new generation of athletes and
          amateur enthusiasts (teqers), whose ambition is to develop their
          technical skills, concentration and stamina. The sport, which was
          created in Hungary in 2012 is a truly gender-equitable game, as the
          rules define that teqball shall be played between two (singles) or
          four players (doubles), irrespective of gender. The sport follows a
          points-based scoring format and can be played on various surfaces such
          as sand, acrylic or indoors.
        </p>
        <div className="imageContainer">
          <figure>
            <img src={TeqballTable} alt="Teqball table" />
          </figure>
        </div>
        <p>
          Teqball allows players a maximum of three touches before returning the
          ball to the opponent, so if you can juggle a football three times, you
          will excel at teqball as well. Teqball is the purest use of a
          football, with the rule of no physical contact allowed between the
          players, or between the players and the table, helping eliminate the
          risk of impact injuries.
        </p>
      </div>
      <VideoEmbedContent
        src={"https://www.youtube-nocookie.com/embed/8-vraWAn7tg"}
      />
      <h2 className="sectionTitle subTitle">Rules of the game</h2>
      <VideoEmbedContent
        src={"https://www.youtube-nocookie.com/embed/R7otmP3k2RM"}
      />
    </section>
  );
}
