import React from "react";
import PlaceHolderSite from "../../PlaceHolderSite/PlaceHolderSite";

export default function DcHome() {
  return (
      <div id="DcTeqers">
        <PlaceHolderSite city={'dc'}/>
      </div>
  )
}
