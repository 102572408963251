import React from "react";
import "./PlaceHolderSite.scss";
import LogoUsTeqers from "../../images/logos/logo_usteqers.png";
import LogoSdTeqers from "../../images/logos/logo_sdteqers.png";
import LogoMiTeqers from "../../images/logos/logo_miteqers.png";
import LogoLaTeqers from "../../images/logos/logo_lateqers.png";
import LogoNyTeqers from "../../images/logos/logo_nyteqers.png";
import LogoDcTeqers from "../../images/logos/logo_dcteqers.png";
import { Link } from "react-router-dom";
import VideoEmbedContent from "../VideoEmbedContent/VideoEmbedContent";

export default function PlaceHolderSite({ city }) {
  const getCityLogo = cityname => {
    switch (cityname) {
      case "us":
        return LogoUsTeqers;
      case "sd":
        return LogoSdTeqers;
      case "mi":
        return LogoMiTeqers;
      case "la":
        return LogoLaTeqers;
      case "ny":
        return LogoNyTeqers;
      case "dc":
        return LogoDcTeqers;
      default:
        return LogoUsTeqers;
    }
  };
  return (
    <section className="placeHolderSite">
      <div className="fullscreenContainer">
        <div className="placeHolderContent">
          <div className="logoContent">
            <figure>
              <img src={getCityLogo(city)} alt="US Teqers" width={"180px"} />
            </figure>
          </div>
          <div className="textContent">
            <p>
              We are excited and looking to introduce the sport of Teqball to
              your state, city and community.
            </p>
            <p>
              If you are an organization, club or individual who is interested
              in running a league, tournament or forming your own Teqball club,
              you can contact us at:{" "}
              <a href="mailto:info@lateqers.com">info@lateqers.com</a> or call
              us at <strong>773-766-6485</strong> for further information.
            </p>
            <VideoEmbedContent
                src={"https://www.youtube-nocookie.com/embed/vR_h5275_TI"}
            />
            <Link to={"/"} className="ghostButton">
              Back to main page
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
