import React from "react";
import "./StickyHeader.scss";
import LogoLaTeqers from "../../images/logos/logo_lateqers.png";
import MenuContent from "../MenuContent/MenuContent";
import CurvedDividerTop from "../CurvedDividerTop/CurvedDividerTop";

export default function StickyHeader({ sticky }) {
  return (
    <div id="stickyMenu">
      <nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
        <div className="dividerContainerTop orange">
          <CurvedDividerTop />
        </div>
        <div className="navbar--logo-holder">
          {sticky ? (
            <img src={LogoLaTeqers} alt="logo" className="navbar--logo" />
          ) : null}
        </div>
        <MenuContent className="menuContent" />
      </nav>
    </div>
  );
}
