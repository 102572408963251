import React from 'react';
import { HashRouter, Route } from "react-router-dom";
import CitySelector from "./components/CitySelector/CitySelector";
import UsHome from "./components/UsTeqers/UsHome/UsHome";
import SdHome from "./components/SdTeqers/SdHome/SdHome";
import MiHome from "./components/MiTeqers/MiHome/MiHome";
import LaHome from "./components/LaTeqers/LaHome/LaHome";
import NyHome from "./components/NyTeqers/NyHome/NyHome";
import DcHome from "./components/DcTeqers/DcHome/DcHome";
import './App.scss';

export default function App() {
  return (
    <div id="App">
      <HashRouter>
          <Route path="/" exact component={CitySelector}></Route>
          <Route path="/usteqers" exact component={UsHome}></Route>
          <Route path="/sdteqers" exact component={SdHome}></Route>
          <Route path="/miteqers" exact component={MiHome}></Route>
          <Route path="/lateqers" exact component={LaHome}></Route>
          <Route path="/nyteqers" exact component={NyHome}></Route>
          <Route path="/dcteqers" exact component={DcHome}></Route>
      </HashRouter>
    </div>
  );
}

