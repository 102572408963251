import React from "react";
import "./Footer.scss";
import TeqballLogo from "../../images/footer/footer_logo_teqball.png";
import LogoUsTeqers from "../../images/footer/footer_logo_usteqers.png";
import LogoSdTeqers from "../../images/footer/footer_logo_sdteqers.png";
import LogoMiTeqers from "../../images/footer/footer_logo_miteqers.png";
import LogoLaTeqers from "../../images/footer/footer_logo_lateqers.png";
import LogoNyTeqers from "../../images/footer/footer_logo_nyteqers.png";
import LogoDcTeqers from "../../images/footer/footer_logo_dcteqers.png";
import { Link } from "react-router-dom";

export default function Footer({ selected }) {
  return (
    <footer>
      <div className="logoContainer">
        <figure>
          <img
            src={TeqballLogo}
            width="160"
            alt="Teqball, the world is curved!"
          />
        </figure>
      </div>
      <div className="footerSiteSelector">
        <div className="siteLink">
          <Link to={"/usteqers"}>
            <img
              src={LogoUsTeqers}
              className={`cityLogoFooter ${selected == 'usteqers' ? 'active' : ''}`}
              alt="US Teqers"
            />
          </Link>
        </div>
        <div className="siteLink">
          <Link to={"/sdteqers"}>
            <img
              src={LogoSdTeqers}
              className={`cityLogoFooter ${selected == 'sdteqers' ? 'active' : ''}`}
              alt="San Diego Teqers"
            />
          </Link>
        </div>
        <div className="siteLink">
          <Link to={"/miteqers"}>
            <img
              src={LogoMiTeqers}
              className={`cityLogoFooter ${selected == 'miteqers' ? 'active' : ''}`}
              alt="Miami Teqers"
            />
          </Link>
        </div>
        <div className="siteLink">
          <Link to={"/lateqers"}>
            <img
              src={LogoLaTeqers}
              className={`cityLogoFooter ${selected == 'lateqers' ? 'active' : ''}`}
              alt="Los Angeles Teqers"
            />
          </Link>
        </div>
        <div className="siteLink">
          <Link to={"/nyteqers"}>
            <img
              src={LogoNyTeqers}
              className={`cityLogoFooter ${selected == 'nyteqers' ? 'active' : ''}`}
              alt="New York Teqers"
            />
          </Link>
        </div>
        <div className="siteLink">
          <Link to={"/dcteqers"}>
            <img
              src={LogoDcTeqers}
              className={`cityLogoFooter ${selected == 'dcteqers' ? 'active' : ''}`}
              alt="Washington DC Teqers"
            />
          </Link>
        </div>
      </div>
      <p>LA Teqers, all rights reserved.</p>
    </footer>
  );
}
