import React from "react";
import "./ContactUs.scss";
import { Element } from "react-scroll";
import ContactUsCard from "./ContactUsCard";
import AthleteAvatar from "./AthleteAvatar";
import SVGIcons from "../SVGIcons/SVGIcons";

export default function ContactUs() {
  return (
    <section className="ContactUs">
      <Element name="contactus"></Element>
      <div className="ContentContainer">
        <h1 className="sectionTitle">Contact Us</h1>
        <p>
          For more information contact us at <a href="mailto:info@lateqers.com">info@lateqers.com</a>.
        </p>
        <h2 className="sectionTitle subTitle orange">#LATEQERS</h2>
        <div className="socialMediaContainer">
          <a
            href="https://www.facebook.com/La-Teqers-105622384256720"
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <SVGIcons
              name={"facebook"}
              fill={"#656565"}
              width={40}
              height={40}
              viewBox={"0 0 24 24"}
            />
          </a>
          <a
            href="https://www.instagram.com/lateqers"
            target="_blank"
            rel="noopener noreferrer"
            title="Instagram"
          >
            <SVGIcons
              name={"instagram"}
              fill={"#656565"}
              width={40}
              height={40}
              viewBox={"0 0 24 24"}
            />
          </a>
          <a
            href="https://tokvid.com/lateqers/user/6786857970031887365"
            target="_blank"
            rel="noopener noreferrer"
            title="TikTok"
          >
            <SVGIcons
              name={"tiktok"}
              fill={"#656565"}
              width={40}
              height={40}
              viewBox={"0 0 24 24"}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCqgwbD9Qn5GgFbt59C9YBEw"
            target="_blank"
            rel="noopener noreferrer"
            title="Youtube"
          >
            <SVGIcons
              name={"youtube"}
              fill={"#656565"}
              width={40}
              height={40}
              viewBox={"0 0 24 24"}
            />
          </a>
        </div>
        <h2 className="sectionTitle subTitle">Meet the Athletes</h2>
        <div className="athletesContainer">
          <AthleteAvatar name={"Andres"} avatar={"andres"} />
          <AthleteAvatar name={"Brett"} avatar={"brett"} />
          <AthleteAvatar name={"Carol"} avatar={"carol"} />
          <AthleteAvatar name={"Daniel"} avatar={"daniel"} />
          <AthleteAvatar name={"Frankie"} avatar={"franki"} />
          <AthleteAvatar name={"Margi"} avatar={"margi"} />
          <AthleteAvatar name={"Nancy"} avatar={"nancy"} />
        </div>
      </div>
    </section>
  );
}
