import React from "react";
import { useState, useEffect } from "react";
import "./HeaderContent.scss";
import MenuContent from "../MenuContent/MenuContent";
import LaTeqersLogo from "../../images/logos/logo_lateqers_small.png";
import BgVideo from "../../videos/teqball_challenge.mp4";
import VideoPoster from "../../images/video_poster.jpg";
import { Link } from "react-scroll";
import { Link as LinkTo } from "react-router-dom";
import { bubble as MobileMenu } from "react-burger-menu";
import "./MobileMenu.scss";

export default function HeaderContent() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header>
      <MobileMenu
        className="mobileMenu"
        width={300}
        disableAutoFocus
        isOpen={isMobileMenuOpen}
      >
        <Link
          activeClass="active"
          to="introduction"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Introduction
        </Link>
        <Link
          activeClass="active"
          to="aboutus"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          About Us
        </Link>
        <Link
          activeClass="active"
          to="trainings"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Trainings
        </Link>
        <a
          href="https://www.fiteq.org/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register / Login
        </a>
        <Link
          activeClass="active"
          className="divider"
          to="contactus"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Contact Us
        </Link>
        <LinkTo to={"/usteqers"} className="locationLink">
          #UsTeqers
        </LinkTo>
        <LinkTo to={"/sdteqers"} className="locationLink">
          #SDTeqers
        </LinkTo>
        <LinkTo to={"/miteqers"} className="locationLink">
          #MITeqers
        </LinkTo>
        <LinkTo to={"/lateqers"} className="locationLink">
          #LATeqers
        </LinkTo>
        <LinkTo to={"/nyteqers"} className="locationLink">
          #NYTeqers
        </LinkTo>
        <LinkTo to={"/dcteqers"} className="locationLink">
          #DCTeqers
        </LinkTo>
      </MobileMenu>
      <div className="dividerContainerBottom">
        <div className="dividerShapeBottom whiteBg"></div>
      </div>
      <div className="BgVideoContainer">
        <video id="BgVideo" poster={VideoPoster} autoPlay muted loop buffered>
          <source src={BgVideo} type="video/mp4" />
          <source src={BgVideo} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="logoContainer">
        <figure>
          <img src={LaTeqersLogo} width="130px" />
        </figure>
      </div>
      <div className="sloganContainer">
        <h1>
          IT IS NOT JUST ABOUT
          <br />
          PLAYING TEQBALL!
        </h1>
        <Link
          className="ghostButton"
          activeClass="active"
          to="contactus"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Join Us
        </Link>
      </div>
      <div className="MenuContainer">
        <MenuContent />
      </div>
    </header>
  );
}
