import React from "react";
import "./VideoEmbedContent.scss"

const VideoEmbedContent = ({src}) => {
    return (
        <div className="videoEmbedContainer">
            <iframe
                width="100%"
                height="100%"
                src={`${src}?rel=0&amp;controls=1&amp;showinfo=0;autoplay=0;mute=0;loop=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};
export default VideoEmbedContent;